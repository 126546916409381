function initAutocompletes() {
  var collection = document.getElementsByClassName('js-places-address');
  for (var i = 0; i < collection.length; i++) {
    initAutocomplete(collection[i]);
  }
}

function initAutocomplete(element) {
  // Enable autocompletion
  var autocomplete = new google.maps.places.Autocomplete(element, {
    componentRestrictions: {country: 'fr'},

    // https://developers.google.com/maps/documentation/places/web-service/autocomplete#types
    types: ['address'],

    // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult.formatted_address
    fields: ['address_components', 'geometry']
  });

  // Listen autocompleter
  google.maps.event.addListener(autocomplete, 'place_changed', function () {
    var place = autocomplete.getPlace();

    if (place.geometry) {
      var address_components = {};
      for (var i=0; i<place.address_components.length; i++) {
        for (var j=0; j<place.address_components[i].types.length; j++) {
          address_components[place.address_components[i].types[j]] = place.address_components[i]['long_name'];
        }
      }

      var address = {
        name: [address_components.street_number, address_components.route].filter(function(v) { return v !== undefined }).join(' '),
        postcode: address_components.postal_code,
        city: address_components.locality,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
      fillAutocompleteInputs(element, address);
    }
  });
}

function fillAutocompleteInputs(element, suggestion) {
  var $inputs = $(element).closest('.js-places-container').find('.js-places-input');
  $inputs.filter('.js-places-input--address').val(suggestion.name);
  $inputs.filter('.js-places-input--postcode').val(suggestion.postcode);
  $inputs.filter('.js-places-input--city').val(suggestion.city);
  $inputs.filter('.js-places-input--lat').val(suggestion.lat);
  $inputs.filter('.js-places-input--lng').val(suggestion.lng);
}

if ($('body').hasClass('env-development')) {
  (function() {
    var collection = document.getElementsByClassName('js-places-address');
    if (collection.length == 0) return;

    var suggestion = {
      name: "2 Rue de la Huguenoterie",
      postcode: "35000",
      city: "Rennes",
      lat: 48.1023,
      lng: -1.69509,
    };

    $('#acf-field_5fddb2f93ed49, #acf-field_5fdefba4547e3').val('Rue de la Huguenoterie, Rennes, France');
    fillAutocompleteInputs(collection[0], suggestion);
  })();
}
