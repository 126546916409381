(function($) {
  $(function() {

    // .c-accordion__panel {
    //   max-height: 0;
    //   overflow: hidden;
    //   transition: max-height 0.4s ease;
    // }

    $('.js-accordion').on('click', function(e) {
      e.preventDefault();

      var $this = $(this);
      var $accordion = $this.parent();
      var $panel = $this.next();

      if ($accordion.hasClass('l-footer__column--active')) {
        $accordion.removeClass('l-footer__column--active');
        $panel.css('max-height', 0);
      }
      else {
        $accordion.addClass('l-footer__column--active');
        $panel.css('max-height', $panel.get(0).scrollHeight + 'px');
      }
    });
  });
})(jQuery);
