//= require magnific-popup/dist/jquery.magnific-popup.js

(function($) {
  $(function() {
    $('.js-help').magnificPopup({
      items: {
          src: '.js-popin',
          type: 'inline'
      }
    });
  });
})(jQuery);
