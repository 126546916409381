(function($) {
  $(function() {
    var $submit = $('button[type=submit]');

    var $area = $('.js-refresh-area');
    if ($area.length == 0) {
      $submit.prop('disabled', false);
      return;
    }

    var url = $area.data('refresh-with');

    $.get(url, function(data, status, xhr) {
      var $data = $(data);
      $area.removeClass('c-docs--loading');
      $submit.prop('disabled', false);

      if ($data.hasClass('c-docs')) {
        $area.html($data.html());
      }
      else {
        $area.find('.c-docs__loadingtitle').html($data.html());
        $area.addClass('c-docs--error');
      }
    });
  });
})(jQuery);
